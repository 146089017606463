/* Portfolio.css */

/* Basic Reset */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

.portfolio {
  padding: 80px 20px;
  background: linear-gradient(135deg, #1f1c2c, black, #1f1c2c);
  text-align: center;
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.portfolio::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.05));
  pointer-events: none;
  transform: rotate(30deg);
}

.portfolio h2 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.portfolio p {
  font-size: 1.25rem;
  margin-bottom: 30px;
  color: #d0d0d0;
}

.portfolio-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.portfolio-item {
  background: linear-gradient(135deg, #232526, #414345);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  padding: 20px; /* Reduced padding for better responsiveness */
  flex: 1 1 calc(33.333% - 20px); /* Adjusted to fit more gracefully */
  max-width: calc(33.333% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.portfolio-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.portfolio-item h3 {
  font-size: 1.75rem;
  color: whitesmoke;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.portfolio-item p {
  font-size: 1.1rem;
  color: #d0d0d0;
  position: relative;
  z-index: 1;
}

.portfolio-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  pointer-events: none;
  transform: rotate(15deg);
}

.portfolio-item .visit-website-button {
  background: wheat;
  color: #000000;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;
  margin: 10px auto; /* Centering horizontally */
}

.portfolio-item .visit-website-button:hover {
  background: #f0e0b8; /* Slightly darker shade */
  transform: scale(1.05);
}

.portfolio-item .visit-website-button svg {
  margin-left: 8px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .portfolio-item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .portfolio-item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .portfolio {
    padding: 60px 10px; /* Adjust padding for smaller screens */
  }

  .portfolio h2 {
    font-size: 2.5rem;
  }

  .portfolio p {
    font-size: 1.1rem;
  }

  .portfolio-item {
    flex: 1 1 calc(100% - 20px); /* Full width on smaller screens */
    max-width: calc(100% - 20px);
  }

  .portfolio-item h3 {
    font-size: 1.5rem;
  }

  .portfolio-item p {
    font-size: 1rem;
  }

  .portfolio-item .visit-website-button {
    font-size: 0.9rem;
    padding: 10px 20px; /* Adjust button padding */
  }
}
