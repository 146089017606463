@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

.footer {
  padding: 40px 20px;
  background: linear-gradient(135deg, #1c1c1c, #2a2a2a);
  color: #e0e0e0;
  text-align: center;
  position: relative;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.footer::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.05));
  pointer-events: none;
  transform: rotate(30deg);
}

.footer .contact-info {
  margin-bottom: 20px;
}

.footer h3 {
  margin-bottom: 10px;
  font-weight: 700;
}

.footer p {
  margin: 10px 0;
  font-size: 1rem;
  color: #ccc;
}

.footer a {
  color: whitesmoke;
  text-decoration: none;
  margin: 0 8px;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: wheat;
  text-decoration: underline;
}

.footer .social-media {
  margin-top: 20px;
}

.footer .social-link {
  margin: 0 10px;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer .social-link:hover {
  color: wheat;
  transform: scale(1.1);
}

.footer .footer-bottom {
  margin-top: 20px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
  }

  .footer p {
    font-size: 0.9rem;
  }

  .footer a {
    font-size: 0.9rem;
    margin: 0 6px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer p {
    font-size: 0.8rem;
  }

  .footer a {
    font-size: 0.8rem;
    margin: 0 4px;
  }

  .footer .social-link {
    margin: 0 6px;
  }
}
