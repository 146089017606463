.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
  background: #0d0d0d; /* Darker background */
}

.carousel-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.carousel-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 50px; /* Remove padding to avoid extra space */
  box-sizing: border-box; /* Ensure padding/border is included in width/height */
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Adjust to 'cover' to fill the container */
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 255, 255, 0.2); /* Neon glow effect */
  filter: drop-shadow(0 0 15px wheat); /* Sci-fi effect */
  transition: transform 0.5s ease, filter 0.5s ease;
}

.carousel-item.active img {
  filter: drop-shadow(0 0 25px wheat); /* Enhanced glow on active item */
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 20px;
  box-sizing: border-box;
}

.carousel-control {
  background: linear-gradient(135deg, wheat 0%, wheat 100%);
  border: none;
  color: #0d0d0d;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 10px wheat(0, 255, 255, 0.7);
  transition: background 0.3s, box-shadow 0.3s;
}

.carousel-control:hover {
  background: linear-gradient(135deg, rgb(226, 185, 110) 0%, wheat 100%);
  box-shadow: 0 0 20px wheat;
}

@media (max-width: 768px) {
  .carousel {
    height: 380px;
    display: flex;
    align-items: center;
  }
  .carousel-item.active img {
    filter: drop-shadow(0 0 0px wheat); /* Enhanced glow on active item */
  }

  .carousel-control {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }

  .carousel-item {
    padding: 10px;
    width: 200px;
    margin: 0 px;
  }
}
