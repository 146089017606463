@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

.testimonials {
  padding: 80px 20px;
  background: linear-gradient(135deg, #0f2027, black, #2c5364);
  text-align: center;
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.testimonials::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.05));
  pointer-events: none;
  transform: rotate(30deg);
}

.testimonials h2 {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.testimonial-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.testimonial-card {
  background: linear-gradient(135deg, #232526, #414345);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  padding: 40px;
  flex: 1 1 calc(33.333% - 40px);
  max-width: calc(33.333% - 40px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.testimonial-image {
  position: relative;
}

.testimonial-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.rating {
  display: flex;
  justify-content: center;
}

.star {
  font-size: 1.25rem;
  color: #d0d0d0;
  margin: 0 2px;
}

.star.filled {
  color: #f0e0b8;
}

.testimonial-content h3 {
  font-size: 1.75rem;
  color: whitesmoke;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.testimonial-content p {
  font-size: 1.1rem;
  color: #d0d0d0;
  position: relative;
  z-index: 1;
}

@media (max-width: 1200px) {
  .testimonial-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .testimonial-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .testimonials {
    padding: 60px 15px;
  }

  .testimonials h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  .testimonials {
    padding: 40px 10px;
  }

  .testimonials h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .testimonial-content h3 {
    font-size: 1.5rem;
  }

  .testimonial-content p {
    font-size: 1rem;
  }
}
