.stacks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh; /* Adjust based on your layout */
  overflow: hidden;
  width: 30%;
  border-radius: 0px;
  /* box-shadow: 0 4px 20px wheat; */
  /* background: whitesmoke;  */
  margin-top: 30px;
  /* Add responsive adjustments */
  flex-wrap: wrap;
}

.icon {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  background-size: cover;
  background-position: center;
  border-radius: 0%;
  transition: transform 1s ease, opacity 1s ease;
  opacity: 0;
  /* Add responsive adjustments */
}

.icon-1, .icon-2 {
  animation: slideFromLeft 10s infinite;
}
.icon-4 {
  height: 55px;
  width: 55px;
  margin: 0 5px;
}
.icon-5 {
  height: 60px;
  width: 60px;
  margin-left: 0;
}


.icon-4, .icon-5 {
  animation: slideFromRight 10s infinite;
}

.icon-center {
  animation: zoomIn 10s infinite;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Media Queries for responsiveness */

@media (max-width: 1200px) {
  .stacks-container {
    width: 40%;
  }

  .icon {
    
    margin: 0 5px;
  }
}

@media (max-width: 992px) {
  .stacks-container {
    width: 50%;
    height: 10vh; /* Adjust height for smaller screens */
  }

  .icon {
    
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .stacks-container {
    width: 70%;
    height: 8vh; /* Adjust height for smaller screens */
    margin-top: 20px;
  }

 
}

@media (max-width: 576px) {
  .stacks-container {
    width: 100%;
    height: 15vh; /* Adjust height for smaller screens */
  }
  .icon-3 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 10px; /* Adjust width */
    height: 10px; /* Adjust height */
    margin: 0 5px;

}

  .icon-4 {
    margin: 0;
    padding: 15px;
    box-sizing: border-box;
    height: 55px;
    width: 55px;
    margin: 0 5px;

  }
  .icon-5 {
    margin: 0;
    padding: 0;
    height: 60px;
    width: 60px;
    box-sizing: border-box;
  }


  
}
