@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

.about {
  padding: 80px 20px;
  background: linear-gradient(135deg, #1f1c2c, black, #1f1c2c);
  text-align: center;
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.about::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.05));
  pointer-events: none;
  transform: rotate(30deg);
}

.about h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.about p {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  color: #d0d0d0;
  position: relative;
  z-index: 1;
}

.about p::selection {
  background: rgba(255, 255, 255, 0.2);
}

.about p::after {
  content: '';
  display: block;
  width: 60%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin: 40px auto 0;
}
