@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

/* Header styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 2%;
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  background: rgba(16, 16, 16, 0.8);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.header.scrolled {
  background: #101010;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: whitesmoke;
  font-family: 'Orbitron', sans-serif;
}

.logo img {
  box-shadow: 0 0px 10px white;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
}

/* Navigation styling */
nav {
  display: flex; /* Show navigation by default */
}

nav ul {
  list-style: none;
  display: flex;
  gap: 25px;
}

nav ul li {
  position: relative;
}

nav ul li a {
  text-decoration: none;
  color: whitesmoke;
  font-weight: bold;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.3s ease;
  outline: none; /* Remove default outline */
}

nav ul li a::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: white;
  transition: width 0.3s ease;
}

nav ul li a:hover::before,
nav ul li a:focus::before {
  width: 100%;
}

nav ul li a:hover,
nav ul li a:focus {
  color: wheat;
  transform: scale(1.1);
}

/* Responsive header styling */
.nav-toggle {
  display: none;
}

@media (max-width: 768px) {
  .header {
    padding: 10px 5%;
  }

  .logo {
    font-size: 1.5rem;
  }

  .nav-toggle {
    display: block;
    cursor: pointer;
    color: #fff;
    font-size: 1.5rem;
    z-index: 1001; /* Ensure it is above other elements */
    padding: 10px;
  }

  nav {
    position: absolute;
    top: 60px;
    right: 20px;
    background: rgba(28, 28, 60, 0.9);
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 999;
    display: none; /* Hidden by default on small screens */
    flex-direction: column;
    gap: 15px;
  }

  nav.active {
    display: flex; /* Show when active */
  }

  nav ul {
    display: flex; /* Display items in a column layout */
    flex-direction: column;
    gap: 15px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

/* Hero content styling */
.hero-content {
  box-sizing: border-box;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 20px;
  z-index: 1;
}

.hero-content h1 {
  font-size: 3rem;
  color: white;
  font-family: 'Orbitron', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7);
  letter-spacing: 1px;
  animation: fadeIn 1s ease-in-out;
}

.hero-content p {
  font-size: 1.3rem;
  margin-bottom: 40px;
  line-height: 1.7;
  color: #e0e0e0;
  max-width: 700px;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;
}

.whatsapp-icon {
  font-size: 1.5rem; /* Adjust this value as needed */
  margin-right: 8px; /* Optional: Adds space between text and icon */
  margin-bottom: 4px;
  vertical-align: middle; /* Optional: Aligns the icon vertically with the text */
}

.cta-button {
  background: wheat;
  margin-top: 20px;
  color: black;
  padding: 10px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 700;
  box-shadow: 0 5px 15px #dfdf3e67;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 1s ease-in-out;
  display: inline-block;
}

.cta-button:hover {
  background: wheat;
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 255, 0, 0.288);
}

/* Fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .hero {
    height: 800px;
  }
  .hero-content h1 {
    font-size: 2.5rem;
  }
}
