/* Contact.css */

/* Basic Reset */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

.contact {
  position: relative;
  padding: 80px 20px;
  background: linear-gradient(135deg, hwb(198 6% 84%), rgb(0, 0, 0), rgb(0, 0, 0));
  text-align: center;
  color: #e0e0e0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: white;
}

.contact::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  background: linear-gradient(transparent, rgba(255, 255, 255, 0.05));
  pointer-events: none;
  transform: rotate(30deg);
}

.contact h2 {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #ffffff;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}

.contact-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
}

.contact form {
  margin: 0 auto;
  text-align: left;
  position: relative;
  width: 100%;
  max-width: 600px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.15);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.contact form label {
  display: block;
  font-size: 1rem;
  margin-bottom: 8px;
  color: #e0e0e0;
}

.contact form input,
.contact form textarea {
  width: 98%;
  padding: 12px 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 8px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.contact form input:focus,
.contact form textarea:focus {
  border: 1px solid #f5deb3;
  box-shadow: 0 0 10px #f5deb3;
  outline: none;
}


.button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact form button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5deb3;
  color: #2f2f2f;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  overflow: hidden; /* Added to contain the loader */
}

.contact form button:hover {
  background-color: #e0c0a4;
  transform: translateY(-2px);
}

.contact form button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.contact form button .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #2f2f2f;
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
  animation: spin 1s linear infinite;
 
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media Queries */
@media (max-width: 768px) {
  .contact {
    padding: 60px 15px;
  }

  .contact h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }

  .contact form {
    padding: 30px;
    max-width: 100%;
  }

  .map-container iframe {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .contact {
    padding: 40px 10px;
  }

  .contact h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .contact form {
    padding: 20px;
  }

  .contact form input,
  .contact form textarea {
    padding: 10px 10px;
  }

  .contact form button {
    padding: 10px 20px;
  }

  .contact form button .loader {
    width: 14px; /* Adjust size for smaller screens */
    height: 14px; /* Adjust size for smaller screens */
  }

  .map-container iframe {
    height: 150px;
  }
}
